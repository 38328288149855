import request from '@/utils/request'

// 产品列表
export function listProduct (params) {
  return request.post('/api/listProduct', params)
}

// 新增产品
export function createProduct (params) {
  return request.post('/api/createProduct', params)
}

// 修改产品
export function editProduct (params) {
  return request.post('/api/editProduct', params)
}

//产品详情
export function infoProduct (params) {
  return request.post('/api/infoProduct', params)
}

//产品删除
export function statusProduct (params) {
  return request.post('/api/statusProduct', params)
}

//产品下拉
export function selectProduct (params) {
  return request.post('/api/selectProduct', params)
}
