<template>
  <div>
    <el-dialog
      :title="title"
      width="770px"
      :close-on-click-modal="false"
      :visible.sync="dialogFormVisible"
      custom-class="customW"
      :before-close="handleClose"
    >
      <el-form
        ref="productForm"
        :model="productForm"
        size="small"
        :rules="rules"
      >
        <el-form-item
          label="商家名称"
          label-width="100px"
          prop="businessId"
          style="margin-bottom: 15px"
        >
          <span>{{ business_name }}</span>
        </el-form-item>
        <el-form-item
          label="产品名称"
          label-width="100px"
          prop="product_name"
          style="margin-bottom: 15px"
        >
          <el-input
            v-model="productForm.product_name"
            placeholder="请输入"
            maxlength="50"
          />
        </el-form-item>
        <el-form-item
          label="产品价格"
          label-width="100px"
          prop="price"
          style="margin-bottom: 15px"
        >
          <el-input v-model="productForm.price" placeholder="请输入" />
        </el-form-item>
        <el-form-item
          label="产品说明"
          label-width="100px"
          prop="description"
          style="margin-bottom: 0px"
        >
          <el-input
            v-model="productForm.description"
            type="textarea"
            :rows="3"
            placeholder="请输入"
          />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="close()" size="small">取 消</el-button>
        <el-button type="primary" @click="confirm('productForm')" size="small"
          >确 定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { createProduct, editProduct, infoProduct } from "@/api/product.js";
import { suggestBusinessList } from "@/api/business.js";
export default {
  name: "produvtModal",
  data() {
    return {
      dialogFormVisible: false,
      title: "新增产品",
      product_id: "",
      businessList: [],
      businessLoading: false,
      productForm: {
        businessId: "",
        product_name: "",
        price: "",
        description: "",
      },
      rules: {
        businessId: [
          { required: true, message: "请选择商家", trigger: "change" },
        ],
        product_name: [
          { required: true, message: "请输入产品名称", trigger: "blur" },
        ],
        price: [{ required: true, message: "请输入产品价格", trigger: "blur" }],
      },
      business_name: "",
    };
  },
  methods: {
    async isShow(type, businessid, val) {
      this.dialogFormVisible = true;
      if (type == "edit") {
        this.title = "修改产品";
        this.product_id = val.product_id;
        this.getInfoProduct(val.product_id);
      } else if (type == "add") {
        this.title = "新增产品";
        this.product_id = "";
        this.productForm.businessId = businessid;
        var businessList = await this.getSelectBusiness("");
        var businessName = "";
        businessList.map((item) => {
          if (item.id == businessid) {
            businessName = item.full_name;
          }
        });
        this.business_name = businessName;
      }
    },
    async getInfoProduct(id) {
      const parme = {
        product_id: id,
      };
      const { code, data } = await infoProduct(parme);
      if (code == 200) {
        this.business_name = data.business_name;
        this.productForm = {
          product_id: data.product_id,
          businessId: data.business_id,
          product_name: data.product_name,
          price: data.price,
          description: data.description,
        };
      }
    },
    async getSelectBusiness(keyword) {
      const { code, data } = await suggestBusinessList({
        keyword: keyword || "",
      });
      if (code == 200) {
        return data;
      }
      return [];
    },
    confirm(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          console.log(this.product_id);
          let res;
          this.product_id
            ? (res = await editProduct(this.productForm))
            : (res = await createProduct(this.productForm));
          if (res.code == 200) {
            this.close();
            this.$message.success("操作成功");
            this.$parent.initData();
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    close() {
      this.dialogFormVisible = false;
      this.productForm = {};
      this.business_name = "";
      this.$refs.productForm.resetFields();
    },
    handleClose(done) {
      done();
      this.close();
    },
  },
};
</script>
<style lang="scss" scoped>
/deep/ .customW {
  border-radius: 10px !important;
  .el-dialog__header {
    border-bottom: 1px solid #e8eaec;
    .el-dialog__title {
      font-size: 14px;
      color: #17233d;
      font-weight: 700;
    }
  }
  .classification {
    margin: 10px 40px;
    .content-form {
      margin: 0px 10px;
    }
  }
  .el-dialog__footer {
    border-top: 1px solid #e8eaec;
  }
}
</style>